import React from "react";
import './WhatDoes.css'
import Romb from '../../assets/images/romb.png'
import Bottle from '../../assets/images/bottle.png'
import "aos/dist/aos.css"

const WhatDoes = () => {
    return (
        <div className="whatDoes">
            <div className="whatDoes-romb">
                <img src={Romb} alt="romb" />
            </div>
            <div className="WhatDoes-main">
                <div data-aos="fade-right" data-aos-once className="whatDoes-info">
                    <h2 className="title">What does iReserve do?</h2>
                    <p className="description">Each collection will be authentic, “reserve certified”, and scarce.</p>
                    <p className="description">Limited Digital assets represent limited physical assets, and each digital asset may be redeemed for the tangible good or sold freely as an NFT on the secondary markets.</p>
                    <p className="description">Each collection we release will be released once and never again. All items will be unique and sought after.</p>
                </div>
                <div data-aos="fade" data-aos-once className="whatDoes-bottle">
                    <img src={Bottle} alt="bottle" />
                </div>
            </div>
        </div>
    );
}

export default WhatDoes;