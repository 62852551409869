import React from "react";
import './Comments.css'
import "aos/dist/aos.css"

const Comments = () => {
    return (
        <div className="comments">
            <div className="comments-image">
                <div data-aos="fade" data-aos-once className="comments-main">
                    <h2 className="title">“ A smart way to invest into the timeless market of rare tangible goods”</h2>
                    <h3 className="comment">iReserve is good for business and consumer</h3>
                    <p className="author">- Rey Toshi </p>
                </div>
            </div>

        </div>
    );
}

export default Comments;