import React, { useEffect } from "react";
import './WhatIs.css'
import Cart from '../../assets/images/buyOnline.png'
import Asterix from '../../assets/icons/star.png'
import "aos/dist/aos.css"

const WhatIs = () => {


    const componentsList: Array<string> = [
        "Crypto Payments",
        "Fiat payment options",
        "Custodial Storage",
        "NFT Burning/Redeeming",
        "Burnt NFT receipts",
        "NFT minting",
        "NFT staking",
        "Virtual storage",
        "Shipping integration",
    ]

    const lengthList = componentsList.length;
    const middle = Math.ceil(lengthList / 2)
    const leftColumn: Array<JSX.Element> = componentsList.slice(0, middle).map(element => {
        return (
            <li key={element} className="component">
                <div className="component-logo">
                    <img src={Asterix} alt="asterix" />
                </div>
                <div className="component-content">
                    {element}
                </div>
            </li>
        );
    })

    const rightColumn: Array<JSX.Element> = componentsList.slice(middle,).map(element => {
        return (
            <li key={element} className="component">
                <div className="component-logo">
                    <img src={Asterix} alt="asterix" />
                </div>
                <div className="component-content">
                    {element}
                </div>
            </li>
        );
    })

    return (
        <div className="whatIs">
            <div data-aos="fade" data-aos-once className="whatIs-image">
                <div className="whatIs-image__img">
                    <img src={Cart} alt="buyOnline" />
                </div>
            </div>
            <div data-aos="fade" data-aos-once className="whatIs-info">
                <div className="whatIs-info__main">
                    <h2 className="title">What is iReserve?</h2>
                    <p className="description">Ireserve is an all in one physical asset NFT marketplace utilizing silicon valley industry standards and disruptive blockchain protocols to easily secure, freely trade and redeem your investments. </p>
                </div>
                <hr className="line" />
                <div className="whatIs-info__platform">
                    <h3 className="title">Platform Components</h3>
                    <div className="listBox">
                        <ul className="left">
                            {leftColumn}
                        </ul>
                        <ul className="right">
                            {rightColumn}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

/*

<div className="whatIs">
            <div data-aos="fade-right" data-aos-once className="whatIs-image">
                <div className="whatIs-image__img">
                    <img src={Cart} alt="buyOnline" />
                </div>
            </div>
            <div data-aos="fade-left" data-aos-once className="whatIs-info">
                <div className="whatIs-info__main">
                    <h2 className="title">What is iReserve?</h2>
                    <p className="description">Ireserve is an all in one physical asset NFT marketplace utilizing silicon valley industry standards and disruptive blockchain protocols to easily secure, freely trade and redeem your investments. </p>
                </div>
                <hr className="line" />
                <div className="whatIs-info__platform">
                <h3 className="title">Platform Components</h3>
                    <div className="listBox">
                        <ul className="left">
                            {leftColumn}
                        </ul>
                        <ul className="right">
                            {rightColumn}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
*/


/* 

*/

export default WhatIs;