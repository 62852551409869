import React, { useEffect } from "react";
import './Hero.css'
import Aos from "aos"
import "aos/dist/aos.css"

const Hero = () => {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <div className="mainHero">
            <div className="hero">
                <div className='hero__image'>
                    <video loop muted playsInline autoPlay className="heroImage" data-aos="fade">
                        <source src="https://smartssi-staticfiles-all-projects.s3.amazonaws.com/ireserve/videos/main.mp4" type="video/mp4" />
                    </video>
                    <div data-aos="fade-right" className='hero__presentation'>
                        <div className="hero__box">
                            <div className="hero__title">
                                <h1>Powering what’s next in <br/>tangible blockchain applications</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

//<Cbutton text="LEARN MORE"></Cbutton>
/*
<div className="hero__image">
    <img src={HeroImage} alt="sample1" className='heroImage' />
</div>
*/

export default Hero;