import React from "react";
import { FC } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import PriceBackground from './_price_flag.svg';
import styled from 'styled-components'

function priceFormat(price:any){
    if(price == "0"){
      return "Free for Members";
    }

    if(price.match(/\D/)){
      let shortedPhrase = price.length > 18? price.slice(0,16) + "...": price
      return shortedPhrase
    }

    else{
      return `${price} ETH`
    }
}

interface Tprops {
  productId: string,
  imageUrl: string | Array<string>,
  productName: string,
  price: string | undefined,
  buyButtonAction: (id: string) => void,
  detailsAction: (id: string) => void
}

export default function MarketplaceCarouselItem(props: Tprops) {
  const { productId, imageUrl, productName, price, buyButtonAction, detailsAction } = props

  return (
    <ItemCard>
      <div className="product_carousel_card">
        { price &&
          <Row className="product_carousel_price_row">
            <Col xs={8} className="d-flex justify-content-center product_carousel_price_col" style={{ backgroundImage: `url(${PriceBackground})`, backgroundSize: "100% 100%" }}>
              Price: <Price> {priceFormat(price)}</Price>
            </Col>
          </Row>
        }

        { !Array.isArray(imageUrl) ? 
          <Row className="product_carousel_image_row mt-5">
            <Col className="d-flex justify-content-center">
              <img src={imageUrl} className={"product_carousel_image"} />
            </Col>
          </Row>
          :
          <CarouselProvider
            naturalSlideWidth={20}
            naturalSlideHeight={20}
            totalSlides={imageUrl.length}
            visibleSlides={1}
            touchEnabled={false}
            infinite={true}
            dragEnabled={false}
            currentSlide={0}
            interval={2000}
            isPlaying={true}
            isIntrinsicHeight={true}
          >
            <Slider>
              { imageUrl.map((imageItem, i) => {
                return (
                  <Slide key={i} index={i} >
                    <Row className="product_carousel_image_row mt-5 ms-0">
                      <Col className="d-flex justify-content-center padding-zero">
                        <img src={imageItem} className={"product_carousel_image"} />
                      </Col>
                    </Row>
                  </Slide>
                )
              })}
            </Slider>

          </CarouselProvider>
        }

        <Row className="product_carousel_name_row mt-5">
          <Col className="d-flex justify-content-center">
            <div>
              <span>{productName}</span>
            </div>
          </Col>
        </Row>

        <Row className="product_carousel_btnBuy_row mt-5 mb-3">
          <Col className="d-flex justify-content-center">
            <button className="px-4 mrkt-pladce-button" onClick={() => { detailsAction(productId) }}>
              See details
            </button>
          </Col>
        </Row>

        {/* <Row className="product_carousel_detail_row mt-5">
            <Col className="d-flex justify-content-center">
                <Button variant="link" onClick={() => { detailsAction(productId) }}>
                    See details
                </Button>
            </Col>
        </Row> */}
        {/* <Row className="product_carousel_btnBuy_row mt-1 mb-3">
            <Col className="d-flex justify-content-center">
                <button className="px-4 mrkt-pladce-button" onClick={() => { buyButtonAction(productId) }}>
                    Purchase
                </button>
            </Col>
        </Row> */}

      </div>

    </ItemCard>        
  )
}

const Price = styled.span`
  margin: 0px 10px 0px 10px;
  white-space: nowrap;
`
const ItemCard = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 30px;
`