import React , {useEffect}from "react";
import './MapBox.css';
import Aos from "aos"
import "aos/dist/aos.css"

const MapBox = () => {
    useEffect(() => {
        Aos.init({duration: 1000});
    }, []);

    return (
        <div className="mapBox" data-aos="fade-right">
            <div className="mapBox-main">
                <div className="mapBox-main__info">
                    <p className="title">ABOUT </p>
                    <hr className="line" />
                    <p className="description">At iReserve we’re building unique blockchain solutions for tangible assets that benefits both business and customer.</p>
                </div>
            </div>
        </div>
    )
}

export default MapBox;