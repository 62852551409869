import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";

const MODAL_SCREENS = {
  WALLET_CONFIGURATION: "WALLET_CONFIGURATION",
  CONFIRMATION_SCREEN: "CONFIRMATION_SCREEN",
  SUCCESS_SCREEN: "SUCCESS_SCREEN"
}

interface Tprops {
  productData: any,
  show: boolean,
  closeAction: () => void
}

export default function MarketplacePayModal(props: Tprops) {
  const { productData, show, closeAction } = props

  const [currentScreen, setCurrentScreen] = useState(MODAL_SCREENS.WALLET_CONFIGURATION);

  const setToConfirmationScreen = () => setCurrentScreen(MODAL_SCREENS.CONFIRMATION_SCREEN);
  const setToSuccessScreen = () => setCurrentScreen(MODAL_SCREENS.SUCCESS_SCREEN);

  useEffect(() => {
    setCurrentScreen(MODAL_SCREENS.WALLET_CONFIGURATION);
  }, [show])

  return (
    <Modal show={show} centered onHide={closeAction}>
      {
        (currentScreen == MODAL_SCREENS.WALLET_CONFIGURATION) ? (
          <>
            <Modal.Header closeButton />
            <Modal.Body>
              <Container>
                <Row>
                  <Col className="d-flex justify-content-center pay_modal_config_amount_col">You need {productData.price ? productData.price : "?"} Eth</Col>
                </Row>
                <Row className="mt-2">
                  <Col className="d-flex justify-content-center pay_modal_config_descr_col">Transfer funds to your wallet or add funds with a card.</Col>
                </Row>
                <Row className="mt-5 pt-5">
                  <Col className="d-flex justify-content-start pay_modal_config_wallet_label_col">Your ETH wallet</Col>
                  <Col className="d-flex justify-content-end pay_modal_config_wallet_balance_col">BALANCE: 0 ETH</Col>
                </Row>
                <Row className="mt-1 pay_modal_config_wallet_input_col">
                  <input className="product_buy_wallet_input" type={"text"} placeholder={"XXXXXXXXXXXXXXXX"} />
                </Row>
                <Row className="mt-5 pt-3 mb-3">
                  <Col className="d-flex justify-content-center pay_modal_config_continue_col">
                    <button className='mrkt-pladce-button' onClick={setToConfirmationScreen}>Continue</button>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </>
        ) : (currentScreen == MODAL_SCREENS.CONFIRMATION_SCREEN) ? (
          <>
            <Modal.Header closeButton />
            <Modal.Body>
              <Container>
                <Row>
                  <Col className="d-flex justify-content-center pay_modal_confirm_title">Confirm</Col>
                </Row>
                <Row className="mt-5">
                  <Col xs={8} className="d-flex justify-content-start pay_modal_confirm_label">Token consumption</Col>
                  <Col xs={4} className="d-flex justify-content-end pay_modal_confirm_amount">{productData.price ? productData.price : "?"} ETH</Col>
                </Row>
                <Row className="mt-5 pt-5">
                  <Col className="d-flex justify-content-end pay_modal_confirm_cancel_col">
                    <button className='mrkt-pladce-button' onClick={closeAction}>CANCEL</button>
                  </Col>
                  <Col className="d-flex justify-content-start pay_modal_confirm_confirm_col">
                    <button className='mrkt-pladce-button' onClick={setToSuccessScreen}>CONFIRM</button>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </>
        ) : (currentScreen == MODAL_SCREENS.SUCCESS_SCREEN) ? (
          <>
            <Modal.Body>
              <Container>
                <Row className="mt-5">
                  <Col className="d-flex justify-content-center">
                    <img className="pay_modal_success_icon" src="http://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/sign-check-icon.png" />
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col className="d-flex justify-content-center pay_modal_success_col">YOUR ORDER HAS BEEN CONFIRMED</Col>
                </Row>
                <Row className="my-5 pb-5">
                  <Col className="d-flex justify-content-center pay_modal_success_button_col">
                    <button className='mrkt-pladce-button' onClick={closeAction}>MARKETPLACE</button>
                  </Col>
                </Row>
              </Container>
            </Modal.Body>
          </>
        ) : (
          <div>
            this wasn't supposed to happen...
          </div>
        )
      }
    </Modal>
  )
}
