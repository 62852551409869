import React, { MouseEventHandler, useState } from "react";
import styled from "styled-components";
import Backdrop from "../../components/modal/backdrop";
import ReactDOM from 'react-dom'
import CloseImage from '../../assets/icons/closeGrey.png'

interface BackdropType {
    closeModal: MouseEventHandler
}

export default function ModalNotification(props: BackdropType) {
    const rootBackDrop = document.getElementById('overlay-root');
    return (
        <React.Fragment>
            <Backdrop onclick={props.closeModal}/>
            {ReactDOM.createPortal(
                <ModalContainer>
                    <CloseButton onClick={props.closeModal} src={CloseImage}/>
                    <BoxMain>
                        <Message>Physical assets will be redeemable starting on July 2023.</Message>
                    </BoxMain>
                </ModalContainer>,
            rootBackDrop as Element)}
        </React.Fragment>
    );
}

const ModalContainer = styled.div`
    position: fixed;    
    z-index: 1001;
    left: calc(100vw/2 - 168px);
    top: calc(100vh/2 - 110px)
`
const BoxMain = styled.div`
    border-radius: 10px;
    background: #F0EEDD;
    text-align: center;
    font-family: 'trade-gothic';
    padding: 32px;
`
const Title = styled.h3`
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 34px;
    text-transform: uppercase;
    color: #84004C;
`
const Message = styled.p`
    font-style: normal;
    font-size: 18px;
    line-height: 25px;
    color: #979696;
    margin: 15px 0px;
`

const CloseButton = styled.img`
    position: absolute;
    width: 10px;
    height: 10px;
    object-fit: cover;
    top: 15px;
    right: 15px;
    cursor: pointer;
`