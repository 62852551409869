import React, { FC, useState, useEffect } from "react"
import styled from "@emotion/styled"
import { useNavigate } from "react-router"
import { useGetMarketplaceCollectionsQuery } from "../../services/apiSlice/apiSlice"
import 'bootstrap/dist/css/bootstrap.css';


import './marketplace.css'

import MockDataCall from "./mock_marketplace_data"
import Cookies from 'universal-cookie'
import ModalNotification from '../../components/modal/modalNotification'
import BlurredWindow from '../../components/modal/blurredWindow'
import { useSelector } from 'react-redux'
import { TrootState, useAppSelector } from '../../app/store'
import IReserve from '../../assets/images/ireserve.png'

import MarketplaceCarousel from "./MarketplaceCarousel"
import MarketplacePayModal from "./MarketplacePayModal"
import { MarketplaceDetailModal } from "./MarketplaceDetailModal"
import { LoginModal } from "./LoginModal"
import NavBar from "../../components/NavBar/NavBar"


const cookies = new Cookies();

const Marketplace: FC = () => {
  // const user = useSelector((state: TrootState) => state.user);
  // const metamask = useAppSelector((state: TrootState) => state.metamask)
  const respCollections = useGetMarketplaceCollectionsQuery({})


  const [modalProductId, setModalProductId] = useState<string>();
  const [buyModalVisible, setBuyModalVisible] = useState<boolean>(false);
  const [detailModalVisible, setDetailModalVisible] = useState<boolean>(false);
  const [loginModalVisible, setLoginModalVisible] = useState<boolean>(false);
  const [islogged, setIslogged] = useState(cookies.get('vrc_user'));
  const [localMockData, setLocalMockData] = useState<any[]>([]);
  const [notificationState, setNotificationState] = useState(false)
  const navigate = useNavigate();

  //const [userLoggedIn, setUserLoggedIn] = useState(user.data? true: false)

  const openBuyModal = (id: string) => {
    if (islogged) {
      setModalProductId(id);
      setBuyModalVisible(true);
    } else {
      // setLoginModalVisible(true);
      navigate('/login');
    }
  }

  useEffect(() => {
    let collectionTokens: any = [];

    if (respCollections.isSuccess) {

      for (let key in respCollections.data) {
        let collection = respCollections.data[key];
        let tokens = collection.token_image || [];
        let extractedPrice = (collection.collection_name === "Third collection example"|| collection.collection_name === "Example")? 'Will be claimed to members' : collection.price;

        for (let j = 0; j < tokens.length; j++) {
          let necessaryTokenInfo = {
            id: tokens[j].id,
            name: tokens[j].title,
            image: tokens[j].url,
            description: tokens[j].desc,
            extraDetails: tokens[j].additional_desc || "Only members can claim marketplace items",
            price: extractedPrice
          }

          collectionTokens = collectionTokens.concat(necessaryTokenInfo);
          console.log(collectionTokens)
        }
      }

      setLocalMockData(collectionTokens)

    }
    // MockDataCall().then((response) => {
    //   setLocalMockData(response)
    // });
  }, [respCollections])

  const openDetailModal = (id: string) => {
    setModalProductId(id);
    setDetailModalVisible(true);
  }

  const getProductFromList = async (id: string) => {
    const localMockFiltered = localMockData.filter((x: any) => { return x.id == id }).pop();
    return localMockFiltered;
  }

  const openNotificationModal = () => { setNotificationState(true) }
  const closeNotificationModal = (event: any) => { setNotificationState(false) }


  // const isLoggedInVerified = user.data? true: false;
  const isLoggedInVerified = true
  const isMetaMaskVerified = true//metamask.state === 'auth' ? true: false;

  return (
    <MarketplaceContainer className="Marketplace">
      <NavBar color="black"/>

      {notificationState && <ModalNotification closeModal={closeNotificationModal} />}
      <BlurredWindow haveLogIn={isLoggedInVerified} haveNft={isMetaMaskVerified}/>

      <CollectionsContainer className="Collections">
        { respCollections.isSuccess &&
          <MarketplaceCarousel
            //itemList={MOCKDATA}
            itemList={localMockData}
            btnBuyAction={openBuyModal}
            btnDetailAction={openDetailModal}
            loggedIn={islogged}
          />
        } 
      </CollectionsContainer>

      {/* <ButtonClaim onClick={openNotificationModal}>Claim my NFTs</ButtonClaim> */}

      <IReserveLogo src={IReserve} alt="IReserve" />

      <Text>
        The iReserve marketplace will be an all in one physical asset NFT marketplace utilizing silicon valley industry standards and disruptive blockchain protocols to easily secure, freely trade and redeem your investments. Vine Reserve Club Members will receive discounts or first access to all unique physical asset NFT collections we release through this marketplace.
      </Text>

      <SpecialLink target='blank' href='https://ireserve.smartssi.net/'>
        <ButtonSpecial > Show Me More </ButtonSpecial>
      </SpecialLink>

      {
        modalProductId && 
        <>
          <MarketplacePayModal
            show={!!buyModalVisible}
            closeAction={() => setBuyModalVisible(false)}
            productData={getProductFromList(modalProductId)}
          />
          <MarketplaceDetailModal
            show={detailModalVisible}
            closeAction={() => setDetailModalVisible(false)}
            productData={getProductFromList(modalProductId)}
            openBuyModal={openBuyModal}
            openLoginModal={() => setLoginModalVisible(true)}
            loggedIn={islogged}
          />
        </>
      }

      <LoginModal
        show={loginModalVisible}
        closeAction={() => setLoginModalVisible(false)}
      />
    </MarketplaceContainer>
  )
}

const MarketplaceContainer = styled.div`
  margin-top: '50px';
  width: 100%;
  min-width: 100%;
  position: relative;
  margin-top: 100px;
  font-family: Montserrat;
  .row {
    margin-right: 0 !important;
  }

  @media only screen and (max-width: 600px){
    .noMargin{
      margin-left: 0;
    }
  }
`

export { Marketplace }

const CollectionsContainer = styled.div`
  display: fixed;
  justify-content: center;
  margin: 50px 0px;
`

const SpecialLink = styled.a`
  color: black; 
  text-decoration: 'none';
`

const Text = styled.p`
  margin: auto;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #686868;
  max-width: 800px;
  text-align: left;
  padding: 0px 20px;  
`

const IReserveLogo = styled.img`
  display: block;
  margin: 100px auto 30px auto;
  max-width: 300px;
`

const ButtonSpecial = styled.button`
  width: 250px;
  margin-top: 30px;
  background: #84004C;
  color: white;
  padding: 10px 30px 10px 30px;
  
  border-radius: 16px;
  border: 1px solid #84004C;
  font-family: 'TradeGothic LT CondEighteen', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18pt;
  text-decoration: none;
  margin-bottom: 100px;
`

// const ButtonClaim = styled.button`
//   width: 250px;
//   background: #84004C;
//   color: white;
//   padding: 10px 30px 10px 30px;
  
//   border-radius: 16px;
//   border: 1px solid #84004C;
//   font-family: 'TradeGothic LT CondEighteen', sans-serif;
//   font-style: normal;
//   font-weight: 300;
//   font-size: 18pt;
//   text-decoration: none;
// `


