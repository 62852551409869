import React from "react";
import { FC } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const LoginModal: FC<{
    show: boolean,
    closeAction: () => void
}> = ({ show, closeAction }) => {
    return (
        <Modal show={show} centered>
            <Modal.Body>
                <Row>
                    <Col className="d-flex justify-content-end" onClick={closeAction}>X</Col>
                </Row>
                <Row>
                    <Col>
                        Welcome to THE RESERVE
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Access your account to buy your favorite item
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <input className="login_modal_input" type={"text"} placeholder={"USERNAME"} />
                        </Row>
                        <Row>
                            <input className="login_modal_input" type={"password"} placeholder={"PASSWORD"} />
                        </Row>
                        <Row>
                            <Button>Sign In</Button>
                        </Row>
                        <Row>
                            Don't have an account? <a href={'signup'}>Sign Up</a>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export { LoginModal }