import React, {useState} from "react";
import "./CollabImage.css"

interface TCollabImage{
    image: string
    title: string
}
export default function CollabImage(props:TCollabImage) {
    return(
        <div className="collabImageMain">
            <div className="collabImageMain-image">
                <img src={props.image} alt="collabImage" />
            </div>
            <div className="collabImageMain-title">
                <p>{props.title}</p>
            </div>
        </div>
    );
}