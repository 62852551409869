import React, {useState} from "react";
import "./CollabsGroup.css"
import CollabImage from './CollabImage'
import Wallet from '../../assets/images/wallet.png'
import Headphone from '../../assets/images/headphone.png'
import WatchClock from '../../assets/images/watchClock.png'
import WatchClock2 from '../../assets/images/watchClock2.png'

export default function CollabsGroup() {
    return(
        <div className="collabsGroup">
            <CollabImage image={Wallet} title={"Quality products guaranteed"}/>
            <CollabImage image={Headphone} title={"Top tech items"}/>
            <CollabImage image={WatchClock} title={"Limited edition accessories"}/>
            <CollabImage image={WatchClock2} title={"Most wanted products"}/>
        </div>
    );
}