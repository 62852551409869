import React, { useState } from "react";
import styled from 'styled-components'
import { NavLink } from "react-router-dom";

interface TBlurredWindow {
    haveLogIn: boolean,
    haveNft: boolean
}
export default function BlurredWindow(props: TBlurredWindow) {
    const { haveLogIn, haveNft } = props;
    const propsEnabled = haveLogIn && haveNft;
    const loginText = haveLogIn? "" : (
        <React.Fragment>
            <NavLink to={"/login"}>login</NavLink> to your account and
        </React.Fragment>
    )
    return (propsEnabled? <React.Fragment/> : (
        <BlockingWindow>
            <MessageBox>This section is available for VRC token holders, please {loginText} link your metamask wallet so we can verify you are a VRC member</MessageBox>
        </BlockingWindow>)
    );
}

const BlockingWindow = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 100;
    backdrop-filter: blur(20px);
`

const MessageBox = styled.div`
    font-family: 'trade-gothic';
    max-width: 600px;
    font-size: 30px;
    background-color: white;
    color: #777777;
    padding: 10px;
    border-radius: 10px;
    & a{
        color: #84004C;
    }
`