import React from "react";
import './Footer.css'
import Romb from '../../assets/images/romb.png'
import Encryption from '../../assets/icons/encryption.png'
import Web from '../../assets/icons/web.png'
import Safe from '../../assets/icons/safe.png'
import Chess from '../../assets/images/chess.png'

const Footer = () => {
    return(
        <div className="footer">
            <div data-aos="fade" data-aos-once className="footer-romb">
                <img src={Romb} alt="Romb" />
            </div>
            <div data-aos="fade" data-aos-once className="footer-info">
                <h2 className="title">How<br/>iReserve Works</h2>
                <p className="description">iReserve involves blockchain technology, Smart Collectible Contracts, and Burning Protocols to deliver secure purchase, redemption and storage solutions for physical and digital assets. </p>
            </div>
            <div data-aos="fade" data-aos-once className="footer-options">
                <ul className="footer-options__list">
                    <li className="encryption"><img src={Encryption} alt="Encryption" className='logo'/>End to End encryption</li>
                    <li className="web"><img src={Web} alt="Web" className='logo'/>Web 3 developed and Integrated solutions</li>
                    <li className="safe"><img src={Safe} alt="Safe" className='logo'/>Safe and optimal smart contracts</li>
                </ul>
            </div>
            <img className="footer-chess" src={Chess} alt="Chess" />
        </div>
    );
}

export default Footer;