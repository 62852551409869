import React from "react";
import './Functions.css';
import FunctionBox from "./FunctionBox";
import Romb from "../../assets/images/romb.png"
import Blockchain from '../../assets/icons/blockchain.png'
import Dollar from '../../assets/icons/dollar.png'
import Nft from '../../assets/icons/nft.png'
import "aos/dist/aos.css"

const Functions = () => {
    return (
        <div className="functions">
            <div className="romb">
                <img src={Romb} alt="Romb" />
            </div>
            <div className="functions-main">
                <div className="title" data-aos="fade-right" data-aos-once>
                    <h2>Powering the future of marketplace utility using:</h2>
                </div>
                <div className="functions-grid" data-aos="fade-up" data-aos-once>
                    <FunctionBox icon={Blockchain}>Blockchain technology utilizing Secure Smart Contracts </FunctionBox>
                    <FunctionBox icon={Dollar}>Fiat Payment option integration, NFT burning/staking, and custodial storage</FunctionBox>
                    <FunctionBox icon={Nft}>Staging, creation and deployment of certified backed physical asset NFT’s</FunctionBox>
                </div>
            </div>
        </div>
    )
}

export default Functions;