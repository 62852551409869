import React, { MouseEventHandler, useState } from "react";
import styled from "styled-components";
import ReactDOM from 'react-dom'

interface BackdropType {
    onclick: MouseEventHandler
}
export default function Backdrop(props: BackdropType) {
    const rootBackDrop = document.getElementById('backdrop-root');
    return (
        <React.Fragment>
            {ReactDOM.createPortal(
            <BackDrop onClick={props.onclick}/>
            , rootBackDrop as Element)}
        </React.Fragment>
    );
}

const BackDrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.75);
`