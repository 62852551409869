import React, { ChangeEvent } from "react"
import './FormInput.css'

type TFormInput = {
    index: number,
    title: string,
    boxType: string,
    name: string,
    inputChange: (event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => void,
}

const FormInput = (props: TFormInput) => {

    const titleParam = props.title.toLowerCase().replace(/\s/g, "_");
    const box = props.boxType === "simple" ?
        <input type="text" name={props.name} id={titleParam} className="fusion-form-input" tabIndex={props.index} onChange={props.inputChange}/>
    : 
        <textarea cols={40} rows={4} id="message" name={props.name} className="fusion-form-input" required={true} aria-required="true" data-holds-private-data="false" tabIndex={props.index} onChange={props.inputChange}></textarea>    
    return (
        <div className="formInput">
            <div className="formInput-wrapper">
                <label htmlFor={titleParam}>{props.title} <abbr className="fusion-form-element-required" title="required">*</abbr></label>
            </div>
            {box}
        </div>
    );
}

export default FormInput