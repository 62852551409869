import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import './NavBar.css'
import navLogo from "../../assets/images/navLogo.png"
import navLogo2 from "../../assets/images/navLogo2.png"

type NavObject = {
    component: any,
    path: string
};

type TNavBar = {
    color: string | undefined
}

const navElements: Array<NavObject> = [
    {
        "component": "HOME",
        "path": "/"
    },
    {
        "component": "MARKETPLACE",
        "path": "/marketplace"
    },
    {
        "component": "ABOUT",
        "path": "/about"
    },
    {
        "component": "CONTACT ME",
        "path": "/contact"
    }
]

const NavBar = (props: TNavBar) => {
    //const blackClass = props.color === "black" ? ' black-font': '';
    const blackClass = '';

    const [burger, setBurger] = useState('')
    const animateBurger = () => {
        setBurger(prevState => prevState.length ? '' : 'change')
    }

    /*const [color, setColor] = useState(false);
    const changeColor= () => {
        if(window.scrollY > 90) setColor(true);
        else setColor(false);
    }
    window.addEventListener('scroll', changeColor);*/

    const color = true;
    const navOptionsTags = navElements.map(element => {
        return <NavLink to={element.path} className={`navBar__option${blackClass}`} key={element.component} style={color? {'color': '#000000'}/*{'color': '#84004C'}*/ : {}}>{element.component}</NavLink>
    })

    const imageLogo = color? navLogo2: navLogo;
    const burgerColor = color? "#84004C": "#FFFFFF";
    return (
        <nav className={`navBar ${burger}${blackClass}`} style={color? {'background': '#FFFFFF'} : {'background': '#84004C'}}>
            
            <div className="navBar__logo">
                <NavLink to='/'>
                <img src={imageLogo} alt="Logo" />
                </NavLink>
            </div>
            
            <div className={`navBar__options`} style={color? {'background': '#FFFFFF'} : {'background': '#84004C'}}>
                {navOptionsTags}
            </div>
            <div className={`container`} onClick={animateBurger}>
                <div className="bar1" style={{background:burgerColor}}></div>
                <div className="bar2" style={{background:burgerColor}}></div>
                <div className="bar3" style={{background:burgerColor}}></div>
            </div>
        </nav>
    );
}

export default NavBar;