import React from 'react';
import './About.css'
import NavBar from '../../components/NavBar/NavBar';
import MapBox from '../../components/MapBox/MapBox';
import Functions from '../../components/Functions/Functions';
import Scalability from '../../components/Scalability/Scalability';
import ReadyFooter from '../../components/ReadyFooter/ReadyFooter';

export default function About () {

    return(
        <div className="about-main">
            <NavBar color="black"/>
            <MapBox/>
            <Functions/>
            <Scalability/>
            <ReadyFooter/>
        </div>
    )
}