import React from 'react';
import styles from './Contact.module.css'
import NavBar from '../../components/NavBar/NavBar';
import FormSection from '../../components/FormSection/FormSection';

export default function Contact () {
    return(
        <div className={styles["home-main"]}>
            <NavBar color="black"/>
            <FormSection/>
        </div>
    )
}