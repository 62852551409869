import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./ReadyFooter.css"
import NavLogo from "../../assets/images/navLogo.png"
import Twitter from "../../assets/icons/twitter-24.png"
import Facedbook from "../../assets/icons/facebook-24.png"
import Instagram from "../../assets/icons/instragram-24.png"
import Linkedin from "../../assets/icons/linkedin-24.png"

export default function ReadyFooter() {
    const socialMediaList = [
        {
            name: "Linkedin",
            link: "#",
            image: Linkedin
        },
        {
            name: "Twitter",
            link: "#",
            image: Twitter
        }
    ]
    const socialElements = socialMediaList.map(x => {
        return (
            <div className={`link-${x.name}`} key={x.name}>
                <a href={x.link}><img src={x.image} alt={x.name} /></a>
            </div>
        )
    })
    return (
        <div className="readyFooter">
            <div className="readyFooter__up">
                <div data-aos="fade-right" data-aos-once className="readyMain">
                    <h1 className="title">Ready to Get Started?</h1>
                    <NavLink to={"/contact"}><button className="getTouch">Get in Touch</button></NavLink>
                </div>
            </div>
            <div className="readyFooter__down">
                <div className="readyDown__container">
                    <div className="ready__mainLogo">
                        <div className="ready__logo">
                        <NavLink to='/'><img src={NavLogo} alt="navLogo" /></NavLink>
                        </div>
                    </div>
                    <div className="ready__end">
                        <div className="readyEnd__container">
                            <div className="ready__copyright">
                                <p>© Copyright 2022 | IReserve | All Rights Reserved</p>
                            </div>
                            <div className="ready__social">
                                {/* {socialElements} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}