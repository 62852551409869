import React, { ChangeEvent, useEffect, useState } from "react"
import styled from "styled-components"
import './Form.css'
import { contactRequest } from "../../services/contact/types"
import { contactUS } from '../../services/contact/api'
import { setLogEvent } from "../popUp/popUpSlice"
import FormInput from "./FormInput"

import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'

import loadingGif from '../../assets/gifs/loading.gif'
import { useAppDispatch } from "../../app/store"
import { useNavigate } from "react-router-dom"

const contactForm = {
    email: undefined,
    first_name: undefined,
    last_name: undefined,
    phone: undefined,
    message: undefined,
}

const Form = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [formData, setFormData ] = useState<contactRequest>(contactForm)
    const [ready, setReady ] = useState(false);
    const [loading, setLoading ] = useState(false)
    const [phoneValue, setPhoneValue] = useState<string>()

    function inputChange(event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) {
        setFormData((state) => ({
            ...state,
            [event.target.name]: event.target.value
        }))
    }

    useEffect(() => {
        console.log(formData)
    }, [formData])

    useEffect(() => {
        const thereIsUndefined = Object.entries(formData).some(([prop, value]) => {
            if (value === undefined || value === '') return true
            else return false
        })

        if (!thereIsUndefined)setReady(true)
        else setReady(false)
    }, [formData])

    function SendContactData(event: React.SyntheticEvent) {
        event.preventDefault()
        setLoading(true)

        contactUS(formData)
        .then((resp) => {
            setLoading(false)
            console.log(resp)
            dispatch(setLogEvent({ type: 'info', message: 'Request sent successfully' }))
            setTimeout(() => {
                navigate('/')
            }, 2000)
            
        })
        .catch((err) => {
            setLoading(false)
            console.log(err)
        })

    }

    return (
        <div className="form">
            <div className="formSection-form__box">
                <div className="formSection-title">
                    <h3>Let’s Connect</h3>
                </div>
                <hr />
                <form className="formSection-boxContent"
                    onSubmit={SendContactData}
                >
                    <div className="boxContent-firstSection">
                        <ContactInput title="First Name" boxType="simple" index={1}
                            name='first_name'
                            inputChange={inputChange}
                        />
                        <ContactInput title="Email Address" boxType="simple" index={3}
                            name='email'
                            inputChange={inputChange}
                        />
                    </div>
                    <div className="boxContent-secondSection">
                        <ContactInput title="Last Name" boxType="simple" index={2}
                            name='last_name'
                            inputChange={inputChange}
                        />

                        {/*<ContactInput title="Phone number" boxType="simple" index={4}
                            name='phone'
                            inputChange={inputChange}
                        /> */}

                        <div className="formInput">
                            <div className="formInput-wrapper">
                                <label htmlFor='contact-phone-input'>
                                    Phone number
                                    <abbr className="fusion-form-element-required" title="required">*</abbr>
                                </label>
                            </div>
                            <PhoneInput
                                international
                                id='contact-phone-input'
                                style={{ borderColor: 'red' }}
                                className="fusion-form-input"
                                defaultCountry="US"
                                name='phone'
                                placeholder="Enter your phone number"
                                value={phoneValue}
                                onChange={(event) => {
                                    setPhoneValue(event)
                                    console.log(event)
                                    setFormData((state) => {
                                        return {...state,  phone: event}
                                    })
                                }}
                            />
                        </div>
                        
                    </div>
                    <div className="boxContent-thirdSection">
                        <ContactInput title="How can we help?" boxType="custom" index={5}
                            name='message'
                            inputChange={inputChange}
                        />
                    </div>

                    <SendButton
                        type="submit"
                        isReady={ready}
                        disabled={!ready}
                        isLoading={loading}
                    >
                        { loading ? '' : 'Submit' }
                        <LoadingGif isLoading={loading} src={loadingGif}/>
                    </SendButton>
                </form>
                <hr />
            </div>
        </div>
    );
}

export default Form;

interface SendProps { isReady: boolean, isLoading: boolean }
const SendButton = styled.button<SendProps>`
    background-color: ${(args) => args.isReady ? '#a81466': 'gray'};
    border-radius: 5px;
    padding: 15px;
    
    ${(args) => args.isLoading && `
        padding-top: 10px;
        padding-bottom: 10px;    
    `}
`

interface TloadingGif { isLoading: boolean }
  const LoadingGif = styled.img<TloadingGif>`
    height: 22px;
    display: none;
    ${(args) => args.isLoading && `
      display: initial;
    `}
  `

const ContactInput = styled(FormInput)`
    font-family: Roboto Condensed;
`

const PhoneStyledInput = styled(PhoneInput)`
    border-radius: 50px;
`