import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import React, { useLayoutEffect, useState } from "react";
import { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import 'pure-react-carousel/dist/react-carousel.es.css';
import MarketplaceCarouselItem from "./MarketplaceCarouselItem";
import useViewport from "../../hooks/useViewport";

const globalConfig = {
    maxHDWidth: 1340,
    maxSDWidth: 1100,
    mobileThreshold: 950,
    SDthreshold: 1340,
}

interface Tprops {
  itemList: any[],
  btnBuyAction: (id: string) => void,
  btnDetailAction: (id: string) => void
  loggedIn: boolean
}

export default function MarketplaceCarousel(props: Tprops ) {
  const { itemList, btnBuyAction, btnDetailAction, loggedIn } = props

  const { bodyTop, bodyWidth } = useViewport();
  const [isMobile, setIsMobile] = useState(true);

  useLayoutEffect(() => {
      setIsMobile((bodyWidth < globalConfig.mobileThreshold));
  }, [bodyWidth, bodyTop])

  return (
    <CarouselProvider
      naturalSlideWidth={50}
      naturalSlideHeight={100}
      totalSlides={itemList.length}
      visibleSlides={isMobile ? 1 : 3}
      isIntrinsicHeight={true}
    >
      <Row>
        <Col>
          <Slider>

            { itemList.map((v, i) => 
              <Slide key={i} index={i} >
                <MarketplaceCarouselItem
                  productId={v.id}
                  productName={v.name}
                  imageUrl={v.image}
                  buyButtonAction={btnBuyAction}
                  detailsAction={btnDetailAction}
                  price={loggedIn && v.price ? v.price : undefined}
                />
              </Slide>
            )}

          </Slider>
        </Col>
      </Row>
    </CarouselProvider>
  )
}

