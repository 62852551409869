import React from "react";
import CollabsGroup from "./CollabsGroup";
import './Collaborations.css'
import "aos/dist/aos.css"

const Colaborations = () => {
    return (
        <div data-aos="fade-down" data-aos-once className="collaborations">
            <div className="collaborations-info">
                <h2 className="title">Brands & Collaborations</h2>
                <p className="description">Partnering and collaborating with unique brands, communities and individuals to create a new world of trusted rare redeemable digital assets that can be freely and securely traded.</p>
            </div>
            <div className="collaborations-collabs">
                <div className="collabs-info">
                    <h2>Premium Physical Asset NFT’s</h2>
                    <p className="descriptionFont">Each collection will be approved, exceptional, and collectible. We will include limited collections, blind series collections, giveaway collections, and signature collections.</p>
                    <h3>Security, storage and counterfeit protection</h3>
                    <p className="descriptionFont">Redeemed physical assets will be tamper proof. If someone has the physical asset they must have the burnt nft receipt. If the digital asset exists, it is brand new, stored properly and redeemable.</p>
                </div>
                <div className="collabs-images">
                    <CollabsGroup/>
                </div>
            </div>
        </div>
    );
}

export default Colaborations;