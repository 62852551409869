import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

import ConfigContext from "./context/configContext";
import  config  from "./config/config";
import UserProvider from './context/userContext';
//

import ViewportProvider from './context/viewportContext';

import store from './app/store'
import { Provider } from 'react-redux';



const root = ReactDOM.createRoot(
  	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ConfigContext.Provider value={config}>
				<ViewportProvider>
					<UserProvider>
						<Provider store={store}>
							<App />
						</Provider>
					</UserProvider>
				</ViewportProvider>
			</ConfigContext.Provider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
