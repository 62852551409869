import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import fetch, { Headers, Request, Response } from 'cross-fetch'
import { app } from '../config/config'

global.fetch = fetch as any;
global.Headers = Headers as any;
global.Request = Request as any;
global.Response = Response as any;
//global.AbortController = AbortController;

export const apiSlice = createApi({
  reducerPath: 'ireserveApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${app.BACKEND_URL}/`,
    fetchFn: fetch
  }),
  tagTypes: ['Auth', 'Token'],
  endpoints: (builder) => ({

    // -----------------   EVENTS

    getMarketplaceCollections: builder.query({
      query: () => {
        return {
          url: '/collections/marketplace',
          credentials: 'include'
        }
      },
      providesTags: ['Auth', 'Token']
    }),
  })
})

export const { 
  useGetMarketplaceCollectionsQuery  
} = apiSlice