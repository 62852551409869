import React from "react";
import { FC, useState, useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import styled from 'styled-components'

interface TEmptyToken{
    id:string,
    name: string,
    description: string,
    extraDetails?: string,
    price: string|number,
    image: any
}
const emptyToken:TEmptyToken = {
    id:"",
    name: "",
    description: "---",
    extraDetails: undefined,
    price: "",
    image: ""
}

function priceFormat(productData: any){
    const price = productData.price
    if(price == undefined || price == null){
        return "---"
    }

    if(String(price).match(/[\D]/)){
        return price;
    }

    if(price == "0"){
        return "Free for Members";
    }

    else {
        return `${price} ETH`
    }
}

const MarketplaceDetailModal: FC<{
    productData: any,
    show: boolean,
    loggedIn: boolean,
    closeAction: () => void,
    openLoginModal: () => void,
    openBuyModal: (id: string) => void
}> = ({ productData, show, loggedIn, closeAction, openLoginModal, openBuyModal }) => {
    const navigate = useNavigate();
    
    let [stateProductData, setStateProductData] = useState<TEmptyToken>(emptyToken);

    useEffect(()=> {
        productData.then((response:any)=>{
            setStateProductData(response)
        })
    }, [productData])

    let extraDetailsClassSize = stateProductData.extraDetails && stateProductData.extraDetails.length > 170? "details_modal_more_details_col_medium": "details_modal_more_details_col"
    
    return (
        <Modal show={show} size="lg" centered className="details_modal" onHide={closeAction}>
            <Modal.Header closeButton />
            <Modal.Body>
                <Container>
                    <Row className="d-flex justify-content-center detail_modal_contents_row">
                        <Col xs={12} md={(loggedIn ? 6 : 12)} className="detail_modal_contents_main_col">

                            {(!Array.isArray(stateProductData.image)) ? ( 
                                <Row>
                                    <Col className="d-flex justify-content-center details_modal_image_col">
                                        <img src={stateProductData.image} className={"product_detail_image"} />
                                    </Col>
                                </Row>
                            ) : (
                                <CarouselProvider
                                    naturalSlideWidth={300}
                                    naturalSlideHeight={300}
                                    totalSlides={stateProductData.image.length}
                                    visibleSlides={1}
                                    touchEnabled={false}
                                    dragEnabled={false}
                                    infinite={true}
                                    currentSlide={0}
                                    isPlaying={true}
                                    interval={2000}
                                    isIntrinsicHeight={true}>
                                    <Slider>
                                        {
                                            stateProductData.image.map((imageItem: any, i: any) => {
                                                return (
                                                    <Slide key={i} index={i} >
                                                        <Row>
                                                            <Col className="d-flex justify-content-center details_modal_image_col">
                                                                <img src={imageItem} className={"product_detail_image"} />
                                                            </Col>
                                                        </Row>
                                                    </Slide>
                                                )
                                            })
                                        }
                                    </Slider>
                                </CarouselProvider>
                            )}



                            <Row className="mt-5">
                                <Col className="d-flex justify-content-center details_modal_name_col">{stateProductData.name}</Col>
                            </Row>
                            {
                                (!loggedIn) ? (
                                    <Row className="my-5">
                                        <Col className="d-flex justify-content-center details_modal_details_col">{stateProductData.description ? stateProductData.description : "---"}</Col>
                                    </Row>
                                ) : (
                                    null
                                )
                            }
                            <Row className="mt-2">
                                <Col className="d-flex justify-content-center details_modal_price_label_col">
                                    Price:
                                </Col>
                                <Col className="d-flex justify-content-center details_modal_price_data_col">
                                    <Price>{priceFormat(stateProductData)} </Price>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={(loggedIn ? 6 : 12)} className="mt-5 mt-sm-0 detail_modal_contents_main_col">
                            {
                                (loggedIn) ? (
                                    <Row>
                                        <Col className="d-flex justify-content-center details_modal_details_col pb-3">{stateProductData.description ? stateProductData.description : "---"}</Col>
                                    </Row>
                                ) : (
                                    null
                                )
                            }
                            <Row className="my-3">
                                <Col className={`d-flex justify-content-center ${extraDetailsClassSize}`}>
                                    {
                                        (loggedIn) ? (
                                            (stateProductData.extraDetails) ? (
                                                <div className="mb-5 p-3 pt-3">{stateProductData.extraDetails}</div>
                                            ) : (
                                                <div className="mb-5 p-3">{"NO DETAILS FOUND"}</div>
                                            )
                                        ) : (
                                            // <Button variant="link" onClick={openLoginModal}>MORE DETAILS</Button>
                                            <Button variant="link" onClick={() => navigate('/login')}>MORE DETAILS</Button>
                                        )
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className="d-flex justify-content-center my-3 details_modal_btnBuy_col">
                                    <button className='mrkt-pladce-button' onClick={() => {
                                        openBuyModal(stateProductData.id);
                                        closeAction();
                                    }}>Purchase</button>
                                </Col>
                            </Row>
                        </Col>

                    </Row>



                </Container>
            </Modal.Body>
        </Modal>
    )
}

export { MarketplaceDetailModal }

const Price = styled.span`
    white-space: nowrap;
`

