import React, {useEffect} from "react";
import Form from "./Form";
import './FormSection.css';
import Aos from "aos"
import "aos/dist/aos.css"

const FormSection = () => {
    useEffect(() => {
        Aos.init({duration: 1000});
    }, []);

    return (
        <div className="formSection">
            <div className="formSection-flex">
                <div className="formSection-box" data-aos="fade-right">
                    <h1>Contact Us</h1>
                    <p>Ready to talk? Fill out the form and we’ll get in touch.</p>
                </div>
               <Form/>
            </div>
        </div>
    );
}

export default FormSection;