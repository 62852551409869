import React from "react";
import './Scalability.css';
import "aos/dist/aos.css";
import "aos/dist/aos.css";

const Scalability = () => {
    return(
        <div className="scalability">
            <div className="scalability-main">
                <h2 data-aos="fade" data-aos-once>Unlimited Scalability</h2>
                <hr data-aos="fade" data-aos-once/>
                <p data-aos="fade" data-aos-once>Each month 197+ million users use amazon (web 2), and over 1 million users use Opensea (web 3). iReserve will combine the best of amazon and Opensea. iReserve is a scalable platform designed to be a one stop shop for all unique rare physical asset NFT’s that users can invest in, freely trade, redeem and collect.</p>
            </div>
        </div>
    )
}

export default Scalability;