import React from "react";
import './FunctionBox.css';
import Dollar from '../../assets/icons/dollar.png'

type TFunctionBox = {
    icon: string,
    children: string
}

const FunctionBox = (props: TFunctionBox) => {
    const specialClass = props.icon === Dollar? 'dollar' : '';
    return (
        <div className={`functionBox ${specialClass}`}>
            <div className="functionBox-main">
                <div className="functionBox-icon">
                    <img src={props.icon} alt="icon" />
                </div>
                <div className="functionBox-info">
                    <p>{props.children}</p>
                </div>
            </div>
        </div>
    )
}

export default FunctionBox;